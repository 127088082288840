import React from 'react';
import { Helmet } from 'react-helmet';
import ConnectYourCylinderTemplate from '../templates/connectYourCylinderTemplate/connectYourCylinderTemplate';
import GlobalStyles from '../styles/globalStyles';
import Footer from '../components/footer/footer';
export default function ConnectYourCylinder() {
  return (
    <>
      <Helmet>
        <title>How to Connect Your BBQ Propane Cylinder | Pro2Go</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="description"
          content="Instructions for connecting your bbq propane cylinder safely"
        ></meta>
        <meta
          name="keywords"
          content="bbq cylinders,propane cylinders,cylinder exchange service,london ontario,canad."
        ></meta>
      </Helmet>
      <GlobalStyles />
      <ConnectYourCylinderTemplate />
      <Footer />
    </>
  );
}
