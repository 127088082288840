import * as React from 'react';
import * as Styled from './headerWithParagraphStyle';

const HeaderWithParagraph = (props) => {
  return (
    <Styled.Container>
      {props?.header && <Styled.Header5>{props?.header}</Styled.Header5>}
      {props?.subHeader && (
        <Styled.Subheader>{props?.subHeader}</Styled.Subheader>
      )}
      {props?.paragraph && <Styled.ParaText>{props?.paragraph}</Styled.ParaText>}
    </Styled.Container>
  );
};
export default HeaderWithParagraph;
