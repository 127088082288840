import React from 'react'
import * as Styled from './headerWithParagraphListStyles';

const HeaderWithParagraphList = (props) => {
  return (
    <Styled.MainContainer>
      {props?.header && <Styled.Header3>{props?.header}</Styled.Header3>}
      {props?.subHeader && <Styled.Header5>{props?.subHeader}</Styled.Header5>}
      {props?.paragraph && (
        <Styled.Paratext>{props?.paragraph}</Styled.Paratext>
      )}
      {props?.orderedList && (
        <Styled.OrderedList>
          {props?.orderedList.map((title) => {
            return <Styled.List>{title.title}</Styled.List>;
          })}
        </Styled.OrderedList>
      )}
      {props?.unOrderedList && (
        <Styled.UnorderedList>
          {props?.unOrderedList.map((title) => {
            return <Styled.List>{title.title}</Styled.List>;
          })}
        </Styled.UnorderedList>
      )}
    </Styled.MainContainer>
  );
}

export default HeaderWithParagraphList