import styled from "styled-components";

export const Container = styled.div`
  width: 920px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const Subheader = styled.h3`
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    color: #000000;
    font-size: 1.1em;
    line-height: 1.3em;
    font-style: normal;
    font-weight: bold;
    line-height: inherit;
`;
export const Header5 = styled.h5`
    font-family: 'Titillium Web', sans-serif;
    font-weight: 700;
    color: #263b91;
    font-size: 1.25em;
    line-height: 1em;
    margin-top: 0;
    font-style: normal;
    text-rendering: optimizeLegibility;
    margin-bottom: 14px;
    padding: 0;
    direction: ltr;
`;
export const ParaText = styled.p`
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    color: #000000;
    font-size: 1.1em;
    line-height: 1.3em;
    font-style: normal;
    margin-bottom: 17px;
    strong {
    font-weight: bold;
    line-height: inherit;
    }
    a {
        color: #263b91;
    }
    a:hover{
     color:#2ba6cb;
    }
`