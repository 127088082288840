import React from 'react';
import * as Styled from './connectYourCylinderTemplateStyles';
import HeaderWithParagraph from '../../components/headerWithParagraph/headerWithParagraph';
import HeaderwithParagraphList from '../../components/headerWithParagraphList/headerWithParagraphList';
import BootstrapNavbar from '../../components/navgationBar/navigationBar';
import {
  ConnectYourCylinderData,
  ConnectYourCylinderData1,
  ConnectYourCylinderData2,
} from '../../utils/constant';
import BackButton from '../../components/helpBackButton/helpBackButton';

const connectYourCylinderTemplate = () => {
  return (
    <React.Fragment>
      <BootstrapNavbar />
      <Styled.Container>
        <Styled.Header1>HOW TO CONNECT YOUR CYLINDER</Styled.Header1>
        <HeaderWithParagraph paragraph={ConnectYourCylinderData.paragraph} />
        <HeaderwithParagraphList header="Remember these tips:" />
        <HeaderwithParagraphList
          subHeader="Ready to connect:"
          orderedList={ConnectYourCylinderData1.orderedList1}
        />
        <HeaderwithParagraphList
          subHeader="Always check cylinder for leaks:"
          orderedList={ConnectYourCylinderData1.orderedList2}
        />
        <Styled.ParaText>
          Note: Be sure to follow any manufacturers’ instructions for the other
          appliances and follow all precautions. Please click here for the
          complete list of{' '}
        </Styled.ParaText>

        <Styled.AnchorTag href={`/safety-guidelines/`}>
          Safety Guidelines.
        </Styled.AnchorTag>
        <BackButton />
      </Styled.Container>
    </React.Fragment>
  );
};

export default connectYourCylinderTemplate;
