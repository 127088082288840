import styled from 'styled-components';

export const Header1 = styled.h1`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #4e4e4e;
  font-size: 1.75em;
  line-height: 1em;
  text-transform: uppercase;
  text-align: inherit;
  margin: 20px 0;
`;

export const Container = styled.div`
  width: 920px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 15px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
    padding: 0 30px;
  }
`;

export const MainContainer = styled.div``;
export const ParaText = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  padding: 0px;
`;
export const AnchorTag = styled.a`
  text-decoration: none;
  color: #263b91;
  font-size: 1.1em;
  cursor: pointer;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  line-height: inherit;
  font-style: normal;
  .hover {
    color: #52b0e3;
  }
`;
