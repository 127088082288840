import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 920px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const Header5 = styled.h5`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  color: #263b91;
  font-size: 1.25em;
  line-height: 1em;
  font-style: normal;
  margin-bottom: 14px;
  text-rendering: optimizeLegibility;
  direction: ltr;
  padding: 0;
`;

export const Paratext = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  margin-bottom: 17px;
  direction: ltr;
  padding: 0;
`;

export const OrderedList = styled.ol`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  /* margin-left: 20px; */
  padding: 0 15px;
`;

export const List = styled.li`
  margin: 0;
  padding: 0;
  font-size: 14px;
  direction: ltr;
  display: list-item;
  text-align: -webkit-match-parent;
`;

export const UnorderedList = styled.ul`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  margin-left: 17px;
  margin-bottom: 17px;
  list-style-position: outside;
  padding: 0;
`;

export const Header3 = styled.h3`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #263b91;
  font-size: 1.5em;
  line-height: 1em;
  margin-bottom: 0.25em;
`;
